body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.App {
	display: flex;
	height: 100dvh;
	width: 100dvw;
	background-color: #ffffff;
	padding: 0;
	margin: 0;
	overflow: scroll;
}

.text-capitalize {
	text-transform: capitalize;
}
:root{
--gauge-circle-size-big: 96px;}