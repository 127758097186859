.good {
	align-items: center;
	background-color: #00cc661a;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	gap: 7px;
	height: 310px;;
	justify-content: center;
	padding: 20px 10px;
	position: relative;
	width: 30%;
	/* margin-top: 20px; */
}

.good .frame {
	height: 56.07px;
	position: relative;
	width: 56.07px;
}

.good .text-wrapper {
	color: #00cc66;
	font-family: "Lato-Bold", Helvetica;
	font-size: 33.6px;
	font-weight: 700;
	letter-spacing: 0;
	line-height: normal;
	position: relative;
	text-align: center;
	white-space: nowrap;
	width: fit-content;
}

.good .your-performance {
	color: #000000;
	font-family: "Lato-Regular", Helvetica;
	font-size: 19.6px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 27.5px;
	position: relative;
	text-align: center;
	width: 300px;
}

.good .span {
	color: #000000;
	font-family: "Lato-Regular", Helvetica;
	font-size: 19.6px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 27.5px;
}

.good .text-wrapper-2 {
	font-family: "Lato-Bold", Helvetica;
	font-weight: 700;
}
